import cn from 'classnames';
import { InterfaceContext } from 'contexts/interface';
import { useContext, useEffect, useMemo, useState } from 'react';
import Sidebar from 'react-sidebar';
import { useFlags } from 'src/config/feature-flags';
import { AccountContext } from 'src/contexts/account';
import { UniversalSearchDialog } from 'src/features/universal-search/views/UniversalSearchDialog';
import ProfileDropdown from './ProfileDropdown';
import SidebarHeader from './SidebarHeader';
import SidebarItems from './SidebarItems';

import './Sidebar.css';
import { Flex } from '@kandji-inc/nectar-ui';

const SidebarWrap = () => {
  const { sidebarOpened, setSidebarOpened } = useContext(InterfaceContext);
  const [searchOpen, setSearchOpen] = useState(false);
  const { 'vis-12112024-universal-search': ffUniversalSearch } = useFlags([
    'vis-12112024-universal-search',
  ]);

  const { currentCompany, userId, userFirstName, userLastName } =
    useContext(AccountContext);

  useEffect(() => {
    if (!ffUniversalSearch) {
      return;
    }
    const hotkeyListener = (e) => {
      if (e.key === 'k' && e.metaKey) {
        // istanbul ignore next
        e.preventDefault();
        setSearchOpen((prev) => !prev);

        // istanbul ignore next
        window.pendo?.track('[Universal Search] Hotkey Open Search', {
          tenantId: currentCompany?.id,
          userId,
          userFirstName,
          userLastName,
        });
      }
    };
    window.addEventListener('keydown', hotkeyListener);
    return () => {
      window.removeEventListener('keydown', hotkeyListener);
    };
  }, [ffUniversalSearch]);

  const sidebar = useMemo(
    () => (
      <Flex flow="column" id="sidebar" data-testid="sidebar" hFull>
        <SidebarHeader />
        <SidebarItems searchOpen={searchOpen} setSearchOpen={setSearchOpen} />
        <ProfileDropdown />
      </Flex>
    ),
    [sidebarOpened, searchOpen, setSidebarOpened],
  );

  return (
    <Sidebar
      sidebar={sidebar}
      open
      onSetOpen={setSidebarOpened}
      shadow={false}
      rootClassName={cn('kandji-sidebar', {
        'kandji-sidebar--opened': sidebarOpened,
      })}
      sidebarClassName={cn('sidebar', {
        'sidebar--opened': sidebarOpened,
      })}
      overlayClassName="kandji-sidebar__overlay"
    >
      <div className="d-none" />
      {ffUniversalSearch && (
        <UniversalSearchDialog
          isOpen={searchOpen}
          onClose={() => setSearchOpen(false)}
        />
      )}
    </Sidebar>
  );
};

export default SidebarWrap;
