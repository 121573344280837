import type { DateFilterTypeHandlerArgs } from '@kandji-inc/nectar-ui';
import type { DateFilterTypeDetails } from '@kandji-inc/nectar-ui';
import { store } from 'app/_store/rootStore';
import { moment } from 'app/components/common/helpers';
import deepcopy from 'deepcopy';

export type DateRange = { from: Date; to: Date };

export function addDay(date: Date | number | string | null): Date {
  const dateObj = new Date(date);
  dateObj.setDate(dateObj.getDate() + 1);

  return dateObj;
}

export function getUserTimeZone() {
  const userSettings = store.getState().account.user.settings as {
    timezone: string;
  };
  return (
    userSettings.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone
  );
}

export function userToSystemDate(date: Date | string | number | null): Date {
  const dateObj = new Date(date);

  const userTimeZone = getUserTimeZone();
  const userTimeOffset = moment.tz
    .zone(userTimeZone)
    .utcOffset(dateObj.getTime());
  const systemTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const systemTimeOffset = moment.tz
    .zone(systemTimeZone)
    .utcOffset(dateObj.getTime());
  const timeDiff = userTimeOffset - systemTimeOffset;

  return new Date(dateObj.getTime() - timeDiff * 60000);
}

export function mapFilterToDetails(
  filter: DateFilterTypeHandlerArgs,
): DateFilterTypeDetails {
  return deepcopy({
    key: filter.filterKey,
    label: filter.filterLabel,
    operator: filter.operatorValue,
    value: filter.filterValue,
    dateRange: filter.dateRange,
    selectedDate: filter.selectedDate,
  });
}

export function getRangeQueryParam(range: DateRange) {
  if (!range.from && !range.to) return undefined;

  const translatedFrom = range.from ? new Date(range.from).toISOString() : '';
  const translatedTo = range.to ? new Date(range.to).toISOString() : '';

  return `${translatedFrom}@${translatedTo}`;
}

export function getUTCDayDate(
  selectedDate: Date | number | string | null,
  direction: 'start' | 'end',
): Date | null {
  if (!selectedDate) return null;

  const selectedDateObj = new Date(selectedDate);

  const userTimeZone = getUserTimeZone();
  const timeZoneOffset = moment().tz(userTimeZone).format('Z');
  const time = direction === 'start' ? '00:00:00.000' : '23:59:59.999';
  const isoDateString = selectedDateObj.toISOString().split('T')[0];
  return new Date(`${isoDateString}T${time}${timeZoneOffset}`);
}

export function getUTCDayTime(
  selectedDate: Date | number | string | null,
  direction: 'start' | 'end',
): string {
  const date = getUTCDayDate(selectedDate, direction);
  return date ? date.toISOString() : '';
}

export function transformDateFilterToRange(
  filter: DateFilterTypeDetails,
  dateBoundaries?: boolean,
): DateRange | null {
  if (!filter) return null;

  const { value, operator, selectedDate, dateRange } = filter;

  if (!value || value === 'all_time' || value !== 'custom_date_range')
    return null;

  const beginningOfTime = dateBoundaries ? new Date(null) : null;
  const endOfTime = dateBoundaries ? new Date('9999-12-12') : null;

  const selectedStart = getUTCDayDate(selectedDate, 'start');
  const selectedEnd = getUTCDayDate(selectedDate, 'end');

  const from = getUTCDayDate(dateRange.from, 'start');
  const to = getUTCDayDate(dateRange.to, 'end');

  if (operator) {
    if (operator === 'eq') return { from: selectedStart, to: selectedEnd };
    if (operator === 'gt') return { from: selectedEnd, to: endOfTime };
    if (operator === 'gte') return { from: selectedStart, to: endOfTime };
    if (operator === 'lt') return { from: beginningOfTime, to: selectedStart };
    if (operator === 'lte') return { from: beginningOfTime, to: selectedEnd };
    if (operator === 'ib') return { from, to: to };
  }

  return { from: null, to: null };
}
