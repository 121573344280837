/* istanbul ignore file */
import { Flex, Icon, Text, Tooltip, styled } from '@kandji-inc/nectar-ui';
import { links } from 'app/common/constants';
import { InterfaceContext } from 'contexts/interface';
import { i18n } from 'i18n';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { memo, useContext, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setPageScroll } from 'src/app/_actions/computer';
import { useFlags } from 'src/config/feature-flags';
import { AccountContext } from 'src/contexts/account';
import FooterItems from './FooterItems';
import SidebarItemLink from './SidebarItemLink';

const HotkeyIconContainer = styled(Flex, {
  visibility: 'hidden',
  alignItems: 'center',
  justifyContent: 'center',
  color: '$neutral70',
  backgroundColor: '$neutral00',
  borderRadius: '$1',
  width: '20px',
  height: '20px',
});

const StyledSearchItem = styled(Flex, {
  cursor: 'pointer',
  fontWeight: '$regular',
  fontSize: '16px',
  border: '1px solid transparent',
  color: 'var(--colors-neutral70)',
  height: '40px',
  padding: '$2 $5',
  marginBottom: '$2',
  '&:hover': {
    backgroundColor: '#EAEAEA',
    borderRadius: 'var(--radii-1)',
    [`& ${HotkeyIconContainer}`]: {
      visibility: 'visible',
    },
  },
  '&[data-active=true]': {
    color: 'var(--colors-blue50)',
  },
});

const SearchItem = ({ onClick, searchOpen }) => {
  const { sidebarOpened } = useContext(InterfaceContext);
  const item = (
    <StyledSearchItem
      data-testid="universal-search-item"
      onClick={onClick}
      data-active={searchOpen}
    >
      <Flex wFull gap="md" alignItems="center">
        <Icon size={24} name="magnifying-glass" />

        {sidebarOpened && (
          <Flex wFull alignItems="center">
            {i18n.t('Search')}
            <Flex flex={1} justifyContent="end" gap="xs" alignItems="center">
              <HotkeyIconContainer>
                <Icon name="command" size="xs" />
              </HotkeyIconContainer>
              <HotkeyIconContainer>
                <Icon name="k" size="xs" />
              </HotkeyIconContainer>
            </Flex>
          </Flex>
        )}
      </Flex>
    </StyledSearchItem>
  );

  return sidebarOpened ? (
    item
  ) : (
    <Tooltip
      side="right"
      theme="dark"
      hideArrow
      content={
        <Flex gap="sm">
          <Text>{i18n.t('Search')}</Text>
          <Text css={{ color: '$neutral40' }}>⌘ K</Text>
        </Flex>
      }
      // zIndex to match the sidebar's zIndex
      css={{ zIndex: 2000, borderRadius: 'var(--radii-1)', padding: '$2 $3' }}
    >
      {item}
    </Tooltip>
  );
};

const Item = memo(({ item, searchOpen }) => {
  const currentParentPage = useLocation()?.pathname;

  const isActive = useMemo(() => {
    const parentTab = `/${get(item.link?.split('/'), '[1]')}`;

    // wtf?
    return (
      (currentParentPage.indexOf(parentTab || item.link) === 0 &&
        item.link !== links.index) ||
      ([links.index, links.computers, links.devices].includes(
        currentParentPage,
      ) &&
        item.link === links.devices) ||
      (links.index === currentParentPage && item.link === links.index)
    );
  }, [item.link, currentParentPage]);

  return (
    <SidebarItemLink
      // An item is active if search is not open on top of it
      active={(isActive && !searchOpen) || (item.isSearch && searchOpen)}
      {...item}
    />
  );
});

Item.propTypes = {
  item: PropTypes.shape({
    link: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    badge: PropTypes.number,
    onClick: PropTypes.func,
    isNew: PropTypes.bool,
  }).isRequired,
  searchOpen: PropTypes.bool.isRequired,
};

const SidebarItems = ({ searchOpen, setSearchOpen }) => {
  const { currentCompany } = useContext(AccountContext);
  const { feature_configuration, billing_type } = currentCompany;
  const dispatch = useDispatch();
  const sidebarRef = useRef(null);
  const {
    'rd-plg-trial-dashboard': ffTrialDashboard,
    'vm_01132025_vm-new-product-badge': ffVulnNewProductBadge,
    'edr_110824_edr-trial-enabled-ui': ffEdrTrial,
    'dexi-12042024-modern-users-page': ffModernUsersPage,
    'vis-12112024-universal-search': ffUniversalSearch,
  } = useFlags([
    'rd-plg-trial-dashboard',
    'vm_01132025_vm-new-product-badge',
    'edr_110824_edr-trial-enabled-ui',
    'dexi-12042024-modern-users-page',
    'vis-12112024-universal-search',
  ]);
  const isEdrSkuEnabled = !!feature_configuration?.edr?.enabled;
  const isVulnSkuEnabled =
    !!feature_configuration?.vulnerability_management?.enabled;

  const isTrial =
    billing_type === 'automated_trial' || billing_type === 'trial';

  const items = useMemo(() => {
    const tabs = [
      ...(isTrial && ffTrialDashboard
        ? [
            {
              link: links.trial,
              name: i18n.t('Trial'),
              icon: 'trophy',
            },
          ]
        : []),
      {
        link: links.devices,
        name: i18n.t('Devices'),
        icon: 'devices',
        /* istanbul ignore next */
        onClick() {
          dispatch(setPageScroll(0));
        },
      },
      {
        link: links.blueprints,
        name: i18n.t('Blueprints'),
        icon: 'kandji-blueprint-light',
      },
      {
        link: links.library,
        name: i18n.t('Library'),
        icon: 'library-grid-light',
      },
      {
        link: ffModernUsersPage
          ? links.users
          : links['integration-users'].withDevices,
        name: i18n.t('Users'),
        icon: 'user-group',
      },
      ...((!isEdrSkuEnabled && ffEdrTrial) || isEdrSkuEnabled
        ? [
            {
              link: links.threat,
              name: i18n.t('Threats'),
              icon: 'shield-halved',
            },
          ]
        : []),
      ...[
        {
          link: links.vulnerability,
          name: i18n.t('Vulnerabilities'),
          icon: 'shield-magnifying-glass-virus',
          isNew: !isVulnSkuEnabled && ffVulnNewProductBadge,
        },
      ],
    ];

    return tabs;
  }, [
    dispatch,
    ffEdrTrial,
    ffTrialDashboard,
    ffModernUsersPage,
    isEdrSkuEnabled,
    isTrial,
  ]);

  return (
    <Flex
      ref={sidebarRef}
      flow="column"
      className="sidebar-items"
      justifyContent="space-between"
      hFull
    >
      <div>
        {ffUniversalSearch && (
          <SearchItem
            onClick={() => setSearchOpen(true)}
            searchOpen={searchOpen}
          />
        )}
        {items.map((item) => (
          <Item key={item.name} item={item} searchOpen={searchOpen} />
        ))}
      </div>
      <div>
        <FooterItems />
      </div>
    </Flex>
  );
};

export default memo(SidebarItems);
