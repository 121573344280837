/* istanbul ignore file */
import { Badge, Box, Flex, Icon, Text, Tooltip } from '@kandji-inc/nectar-ui';
import PropTypes from 'prop-types';
import { memo, useContext } from 'react';
import { Link } from 'react-router-dom';
import { InterfaceContext } from 'src/contexts/interface';

/* istanbul ignore next */
const SidebarItemLink = ({
  active = false,
  icon = null,
  badge = null,
  name,
  link,
  /* istanbul ignore next */
  onClick = () => {},
  customEl = null,
  isNew = false,
}) => {
  const { sidebarOpened } = useContext(InterfaceContext);
  const item = (
    <Flex
      data-testid={`sidebar-${name}`.toLocaleLowerCase()}
      alignItems="center"
      gap="md"
      css={{
        height: '40px',
        padding: '$2 25px',
        color: active ? 'var(--colors-blue50)' : 'var(--colors-neutral70)',
        border: '1px solid transparent',

        '&:hover': {
          backgroundColor: '#EAEAEA',
          borderRadius: 'var(--radii-1)',
        },

        '&:active': {
          border: '1px solid var(--colors-primary_focus_ring)',
        },
      }}
    >
      {icon && (
        <Flex alignItems="center">
          <Icon name={icon} size="md" />
          {!sidebarOpened && isNew && (
            <Box css={{ width: 0 }}>
              <Icon
                name="sparkles"
                style={{
                  width: '16px',
                  position: 'relative',
                  bottom: '6px',
                }}
              />
            </Box>
          )}
        </Flex>
      )}

      {customEl && <Box css={{ width: '24px' }}>{customEl}</Box>}

      {sidebarOpened && (
        <Flex alignItems="center" wFull gap="sm">
          <Text size="3" css={{ fontWeight: '$medium' }}>
            {name}
          </Text>

          {isNew && (
            <Badge
              icon="sparkles"
              compact
              css={{
                alignSelf: 'center',
                backgroundColor: '#4A505C',
                svg: {
                  width: '$4',
                  color: '$neutral0',
                },
              }}
            />
          )}

          {badge > 0 && (
            <Badge
              css={{
                fontSize: '$1',
                backgroundColor: 'var(--colors-blue50)',
                color: 'var(--colors-neutral0)',
              }}
            >
              {badge}
            </Badge>
          )}
        </Flex>
      )}
    </Flex>
  );
  return (
    <Link onClick={onClick} to={link} style={{ textDecoration: 'none' }}>
      {sidebarOpened ? (
        item
      ) : (
        <Tooltip
          hideArrow
          theme="dark"
          side="right"
          content={<Text>{name}</Text>}
          // zIndex to match the sidebar's zIndex
          css={{
            zIndex: 2000,
            borderRadius: 'var(--radii-1)',
            padding: '$2 $3',
          }}
        >
          {item}
        </Tooltip>
      )}
    </Link>
  );
};

SidebarItemLink.propTypes = {
  active: PropTypes.bool,
  icon: PropTypes.string,
  badge: PropTypes.number,
  name: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  customEl: PropTypes.element,
  isNew: PropTypes.bool,
};

export default memo(SidebarItemLink);
