import { memo, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';

import useBlueprintFlow from 'src/features/blueprint-flow/store';

import {
  DropdownMenuPrimitives as Dropdown,
  Flex,
  Icon,
  Text,
} from '@kandji-inc/nectar-ui';
import { logout } from 'app/_actions/account';
import { AccountContext } from 'contexts/account';
import { InterfaceContext } from 'contexts/interface';
import { i18n } from 'i18n';
import { links } from 'src/app/common/constants';
import history from '../../router/history';
import { ClosedSidebarProfileDropdownTrigger } from './ClosedSidebarProfileDropdownTrigger';
import { OpenSidebarProfileDropdownTrigger } from './OpenSidebarProfileDropdownTrigger';
import { ConfirmLogoutModal } from './confirm-logout-modal';

const ProfileDropdown = () => {
  const dispatch = useDispatch();
  const isEditingAssignments = useBlueprintFlow(
    (state) => state.isEditingAssignments,
  );
  const { sidebarOpened, isEditingLibraryItem } = useContext(InterfaceContext);
  const { userFirstName, userLastName, userEmail } = useContext(AccountContext);

  const [isConfirmLogoutModalOpen, setIsConfirmLogoutModalOpen] =
    useState(false);
  const onConfirmLogoutModalOpen = () => setIsConfirmLogoutModalOpen(true);
  const onConfirmLogoutModalClose = () => setIsConfirmLogoutModalOpen(false);
  const onConfirmLogoutModalConfirm = () => {
    setIsConfirmLogoutModalOpen(false);
    dispatch(logout());
  };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  return (
    <Flex justifyContent="center" p3>
      <Dropdown.Root open={isDropdownOpen} onOpenChange={setIsDropdownOpen}>
        {sidebarOpened ? (
          <OpenSidebarProfileDropdownTrigger
            isDropdownOpen={isDropdownOpen}
            userFirstName={userFirstName}
            userLastName={userLastName}
            userEmail={userEmail}
          />
        ) : (
          <ClosedSidebarProfileDropdownTrigger
            isDropdownOpen={isDropdownOpen}
            userFirstName={userFirstName}
            userLastName={userLastName}
          />
        )}
        <Dropdown.Portal>
          <Dropdown.Content
            align="end"
            side="right"
            sideOffset={5}
            css={{ zIndex: 2000, width: '320px' }}
          >
            <Dropdown.Item
              onClick={() => history.push(links.userProfile)}
              data-testid="my-profile-button"
            >
              <Flex gap="xs" p2>
                <Icon name="image-user" size="sm" />
                <Text>{i18n.t('My Profile')}</Text>
              </Flex>
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item
              onClick={
                // If the user is editing a Library Item or an Assignment Map, show a confirmation modal before logging out
                isEditingLibraryItem || isEditingAssignments
                  ? () => onConfirmLogoutModalOpen()
                  : () => dispatch(logout())
              }
              data-testid="log-out-button"
            >
              <Flex p2 wFull justifyContent="space-between">
                <Flex gap="xs">
                  <Icon name="arrow-right-from-bracket" size="sm" />
                  <Text>{i18n.t('Log out')}</Text>
                </Flex>
                <Text variant="description" size={1}>
                  {userEmail}
                </Text>
              </Flex>
            </Dropdown.Item>
          </Dropdown.Content>
        </Dropdown.Portal>
      </Dropdown.Root>

      <ConfirmLogoutModal
        isOpen={isConfirmLogoutModalOpen}
        onClose={onConfirmLogoutModalClose}
        onConfirm={onConfirmLogoutModalConfirm}
      />
    </Flex>
  );
};

export default memo(ProfileDropdown);
