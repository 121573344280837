/* istanbul ignore file */
import { withCache } from 'components/common/hof/caching';
import { i18n } from 'i18n';
// Just a bunch of support functions for redux
/* istanbul ignore file */

import _cond from 'lodash/cond';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import _stubTrue from 'lodash/stubTrue';

import { getAuth0Client } from 'src/auth0';
import { PlatformTypes } from 'src/components/platforms/Platform.types';
import { enrollmentAudience, passportAudience } from 'src/config';
import {
  apiFetchCompany,
  apiFetchUser,
  isSAMLFederatedLogoutRequired,
} from '../api/account';
import {
  clearAuthToken,
  clearInactiveLogout,
  getTenantAuth0Credentials,
  hasAuthToken,
  setInactiveLogout,
} from '../auth';
import {
  adcsLinks,
  agentLinks,
  links,
  passportLinks,
} from '../common/constants';
import { isIpad, isIphone } from '../components/common/helpers';
import history from '../router/history';
import { account } from './action-types';
import { getComputerCount } from './computer';

const fetchAuth0User = () => (dispatch) => {
  // kandji admins accessing company instances from thier admin session will not have an auth0 session
  if (hasAuthToken()) {
    return Promise.resolve();
  }
  return getAuth0Client()
    .getIdTokenClaims()
    .then((claims = { sub: null }) => {
      dispatch({
        type: account.ACCOUNT_AUTH0_USER,
        userId: claims.sub,
      });
    })
    .catch(() => Promise.resolve());
};

const fetchUserWithCheck = withCache(apiFetchUser);
const fetchCompanyWithCheck = withCache(apiFetchCompany);

const fetchUserState = () => (dispatch) =>
  fetchUserWithCheck
    .call()
    .then((data) =>
      i18n
        .configure({
          locale: data.user.locale,
          timeZone: data.user.settings.timezone,
          shouldUseRelativeDates:
            data.user.settings.disable_relative_dates === 'enable', // var name is opposite UI :/
          legacyLocale:
            data.user.settings.preferred_date_format === 'DD/MM/YYYY'
              ? i18n.LOCALES.en_GB
              : i18n.LOCALES.en_US,
        })
        .then(() =>
          dispatch({
            type: account.ACCOUNT_USER_FETCHED,
            user: data.user,
            tenantOverLicenseLimit: data.tenantOverLicenseLimit,
          }),
        ),
    )
    .catch(() => {
      history.push(links.signin);
      return Promise.reject(new Error('No user found'));
    });

const handleTokenRefresh = async (company) => {
  const featureCapabilities = ['vulnerability_management'];
  const enabledCapabilities = {};

  const accessToken = await getAuth0Client().getTokenSilently();
  const tokenPayload = decodeMetadata(accessToken.split('.')[1]);

  // Extract enabled capabilities from the token payload
  Object.keys(tokenPayload).find((key) => {
    if (key.includes('enabled_capabilities') && tokenPayload[key]) {
      tokenPayload[key].split(' ').forEach((capability) => {
        enabledCapabilities[capability] = {
          ...enabledCapabilities[capability],
          enabled: true,
        };
      });
    }
  });

  const isRefreshTokenRequired = featureCapabilities.some((capability) => {
    const companyCapability = _get(company.feature_configuration, capability);
    const tokenCapability = _get(enabledCapabilities, capability);

    return (
      companyCapability?.enabled &&
      !_isEqual(companyCapability, tokenCapability)
    );
  });

  if (isRefreshTokenRequired) {
    await getAuth0Client().getTokenSilently({ ignoreCache: true });
  }
};

export const fetchCompanyState = () => (dispatch) =>
  fetchCompanyWithCheck.call().then(async (company) => {
    await handleTokenRefresh(company);

    dispatch({
      type: account.ACCOUNT_COMPANY_FETCHED,
      company,
    });
  });

export const fetchComputerList = () => (dispatch) =>
  getComputerCount().then((response) => {
    return dispatch({
      type: account.ACCOUNT_COMPUTER_LIST_FETCHED,
      enrolledDevices: response,
    });
  });

export const fetchAccount = () => (dispatch) => {
  dispatch({ type: account.ACCOUNT_FETCHING });
  return Promise.all([
    fetchUserState()(dispatch),
    fetchAuth0User()(dispatch),
    fetchCompanyState()(dispatch),
    fetchComputerList()(dispatch),
  ]).then(() => {
    fetchCompanyWithCheck.reset();
    fetchUserWithCheck.reset();
    dispatch({ type: account.ACCOUNT_FETCHED });
  });
};

export const updateUser = (updates) => ({
  type: account.ACCOUNT_USER_UPDATED,
  updates,
});

export const updateLocale = (locale) => {
  return {
    type: account.ACCOUNT_LOCALE_UPDATED,
    locale,
  };
};

export const logout =
  (wasInactive = false) =>
  async (dispatch) => {
    dispatch({ type: account.ACCOUNT_LOGOUT });
    clearAuthToken();
    if (wasInactive) {
      setInactiveLogout();
    } else {
      clearInactiveLogout();
    }
    const claims = await getAuth0Client().getIdTokenClaims();
    const federated = await isSAMLFederatedLogoutRequired(claims);
    return getAuth0Client().logout({
      returnTo: `${window.location.origin}${links.signin}`,
      ...(federated && { federated }),
    });
  };

// Auth0 account actions
const decodeMetadata = (metadata = '') => {
  const decoded = atob(metadata);
  const fail = {};
  if (decoded) {
    try {
      return JSON.parse(decoded);
    } catch (_) {
      return fail;
    }
  }
  return fail;
};

const getAuth0ClientId = (o) =>
  _get(decodeMetadata(o), ['custom_web_view', 'sso', 'auth0_client_id'], '');

const getManualAuth0ClientId = (o) =>
  _get(decodeMetadata(o), ['auth0_configurations'], '');

const hasAuth0ClientId = ({ metadata = '' }) => !!getAuth0ClientId(metadata);

const isManualEnrollmentAuth = ({ metadata = '' }) =>
  !!getManualAuth0ClientId(metadata);

const isAgentRoute = ({ path }) => Object.values(agentLinks).includes(path);

const isADCSRoute = ({ path }) => Object.values(adcsLinks).includes(path);

const isPassportRoute = ({ path }) =>
  Object.values(passportLinks).includes(path);

const hasSubdomain = ({ subdomain }) => subdomain;

const isADCSApp = ({ subdomain, path }) =>
  hasSubdomain({ subdomain }) && isADCSRoute({ path });

const isPassportApp = ({ subdomain, path }) =>
  hasSubdomain({ subdomain }) && isPassportRoute({ path });

const isWebapp = ({ subdomain, path }) =>
  hasSubdomain({ subdomain }) &&
  !isAgentRoute({ path }) &&
  !isADCSRoute({ path }) &&
  !isPassportRoute({ path });

const setTenantAuth0Credentials = ({ subdomain, dispatch }) =>
  getTenantAuth0Credentials(subdomain)
    .then((data) =>
      dispatch({
        type: account.ACCOUNT_AUTH0_CREDS_FETCHED,
        clientId: data.client_id,
        organizationId: data.organization_id,
        audience: data.audience,
        locale: data.locale,
        error: false,
      }),
    )
    .catch(() => {
      dispatch({
        type: account.ACCOUNT_AUTH0_CREDS_FETCHED,
        clientId: null,
        organizationId: null,
        audience: null,
        error: true,
      });
    });

export const setADCSAuth0Credentials = ({
  subdomain,
  dispatch,
  metadata: encodedMetadata,
}) => {
  const metadata = decodeMetadata(encodedMetadata);
  return getTenantAuth0Credentials(subdomain)
    .then((data) =>
      dispatch({
        type: account.ACCOUNT_AUTH0_CREDS_FETCHED,
        clientId: data.client_id,
        organizationId: data.organization_id,
        audience: data.audience,
        locale: data.locale,
        error: false,
        metadata,
      }),
    )
    .catch(() =>
      dispatch({
        type: account.ACCOUNT_AUTH0_CREDS_FETCHED,
        clientId: null,
        organizationId: null,
        audience: null,
        error: true,
        metadata,
      }),
    );
};

export const setPassportAuth0Credentials = async ({ dispatch, metadata }) => {
  const decodedMetadata = decodeMetadata(metadata);
  const auth0Config = decodedMetadata.auth0_configurations?.[0] || {};
  const locale = _get(decodedMetadata, 'locale', i18n.LOCALES.en_US);
  await i18n.configure({ locale });

  const manualAuthData = {
    type: account.ACCOUNT_AUTH0_CREDS_FETCHED,
    clientId: _get(auth0Config, 'auth0_client_id'),
    clientDomain: _get(decodedMetadata, ['client_domain']),
    connectionName: _get(auth0Config, 'auth0_connection_name'),
    audience: passportAudience,
    error: false,
    metadata,
    locale,
  };

  dispatch(manualAuthData);
};

const setEnrollmentAuth0Credentials = async ({ dispatch, metadata }) => {
  const enrollmentData = decodeMetadata(metadata);
  const callbackUrl = _get(enrollmentData, 'callback_url', '');
  const tempDeviceId = callbackUrl?.substring(
    callbackUrl?.lastIndexOf('/') + 1,
  );

  const locale = _get(enrollmentData, 'locale', i18n.LOCALES.en_US);
  await i18n.configure({ locale });

  dispatch({
    type: account.ACCOUNT_AUTH0_CREDS_FETCHED,
    clientId: _get(enrollmentData, [
      'custom_web_view',
      'sso',
      'auth0_client_id',
    ]),
    clientDomain: _get(enrollmentData, ['client_domain']),
    connectionName: _get(enrollmentData, [
      'custom_web_view',
      'sso',
      'auth0_connection_name',
    ]),
    audience: enrollmentAudience,
    error: false,
    callbackUrl,
    configureUrl: _get(enrollmentData, 'configure_url', ''),
    enablePendo: _get(enrollmentData, 'enable_pendo'),
    tempDeviceId,
    metadata,
    locale,
  });
};

const setManualEnrollmentAuth0Credentials = async ({
  dispatch,
  metadata,
  locale: queryLocale,
}) => {
  const enrollmentData = decodeMetadata(metadata);
  const tempDeviceId = _get(enrollmentData, 'temp_computer');
  const auth0Config = _get(enrollmentData, 'auth0_configurations')[0];
  const code = _get(enrollmentData, 'enrollment_code');
  const platform = _get(enrollmentData, 'platform');

  const metaLocale = _get(enrollmentData, 'locale', i18n.LOCALES.en_US);
  const locale = queryLocale != null ? queryLocale : metaLocale;

  await i18n.configure({ locale });

  let enrollmentUrl: string;
  const baseUrl = window.location.origin;

  if (platform === PlatformTypes.enum.windows) {
    enrollmentUrl = links.windowsEnrollment(code);
  } else if (platform === PlatformTypes.enum.android) {
    enrollmentUrl = links.androidEnrollment(code);
  } else if (isIphone()) {
    enrollmentUrl = links.phoneEnrollment(code);
  } else if (isIpad()) {
    enrollmentUrl = links.tabletEnrollment(code);
  } else {
    enrollmentUrl = links.computerEnrollment(code);
  }

  const callbackUrl = `${baseUrl}${enrollmentUrl}`;

  const manualAuthData = {
    type: account.ACCOUNT_AUTH0_CREDS_FETCHED,
    clientId: _get(auth0Config, 'auth0_client_id'),
    clientDomain: _get(enrollmentData, ['client_domain']),
    connectionName: _get(auth0Config, 'auth0_connection_name'),
    audience: enrollmentAudience,
    error: false,
    callbackUrl,
    tempDeviceId,
    enablePendo: _get(enrollmentData, 'enable_instrumentation'),
    isManualAuth: true,
    metadata,
    locale,
  };
  dispatch(manualAuthData);
};

const setAuth0Error = ({ dispatch }) =>
  dispatch({
    type: account.ACCOUNT_AUTH0_CREDS_FETCHED,
    clientId: null,
    audience: null,
    error: true,
  });

export const fetchAuth0Credentials =
  ({ subdomain, metadata, path, locale }) =>
  (dispatch) =>
    _cond([
      [hasAuth0ClientId, setEnrollmentAuth0Credentials],
      [isPassportApp, setPassportAuth0Credentials],
      [isManualEnrollmentAuth, setManualEnrollmentAuth0Credentials],
      [isADCSApp, setADCSAuth0Credentials],
      [isWebapp, setTenantAuth0Credentials],
      [_stubTrue, setAuth0Error],
    ])({
      subdomain,
      metadata,
      dispatch,
      path,
      locale,
    });
