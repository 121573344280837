import { createReducer } from 'redux-create-reducer';
import { ui } from '../_actions/action-types';

const initialState = {
  currentModal: null,
  isOpen: false,
  isNewModal: false,
  sidebar: {
    opened:
      localStorage.getItem('sidebarPinned') === 'true' ||
      localStorage.getItem('sidebarPinned') === null,
  },
  snackbar: {
    message: '',
    open: false,
  },
};

export default createReducer(initialState, {
  [ui.SET_MODAL](state, action) {
    return {
      ...state,
      isOpen: true,
      isNewModal: action.isNewModal,
      currentModal: {
        name: action.name,
        info: action.info,
      },
    };
  },
  [ui.CLEAR_MODAL](state) {
    return {
      ...state,
      isNewModal: false,
      currentModal: null,
    };
  },
  [ui.CLOSE_MODAL](state) {
    return {
      ...state,
      isOpen: false,
    };
  },
  [ui.SET_SNACKBAR](state, action) {
    return {
      ...state,
      snackbar: { message: action.message, open: true },
    };
  },
  [ui.CLEAR_SNACKBAR](state) {
    return {
      ...state,
      snackbar: { message: state.snackbar.message, open: false },
    };
  },
  [ui.SET_SIDEBAR_OPENED](state, action) {
    return {
      ...state,
      sidebar: {
        ...state.sidebar,
        opened: action.payload,
      },
    };
  },
});
