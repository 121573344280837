import { Button, Flex } from '@kandji-inc/nectar-ui';
import { InterfaceContext } from 'contexts/interface';
import { useCallback, useContext, useState } from 'react';
import KandjiBeeDark from './icons/KandjiBeeDark.svg';
import KandjiLogoDark from './icons/KandjiLogoDark.svg';

const SidebarHeader = () => {
  const { sidebarOpened, setSidebarOpened } = useContext(InterfaceContext);
  const toggle = useCallback(
    () => setSidebarOpened(!sidebarOpened),
    [sidebarOpened],
  );

  const [showExpandCollapseButton, setShowExpandCollapseButton] =
    useState(false);

  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      onMouseEnter={() => setShowExpandCollapseButton(true)}
      onMouseLeave={
        /* istanbul ignore next */ () => setShowExpandCollapseButton(false)
      }
    >
      <Flex
        mt5
        mb5
        alignItems="center"
        css={{ marginLeft: sidebarOpened ? '28px' : '20px', height: '36px' }}
      >
        {sidebarOpened ? (
          <img src={KandjiLogoDark} alt="Kandji Logo" />
        ) : (
          <img src={KandjiBeeDark} alt="Kandji Bee Logo" />
        )}
      </Flex>
      <Flex
        alignItems="center"
        justifyContent="center"
        onClick={(e) => {
          toggle();
          setShowExpandCollapseButton(false);
          e.currentTarget.blur();
        }}
        css={{
          ...(sidebarOpened && {
            padding: '$5',
            '&:hover': { cursor: 'pointer' },
          }),
        }}
      >
        <Button
          icon={{
            name: sidebarOpened
              ? 'arrow-left-from-line'
              : 'arrow-right-from-line',
          }}
          data-testid="expand-collapse-button"
          css={{
            width: '$7',
            height: '$7',
            position: !sidebarOpened ? 'absolute' : 'static',
            left: !sidebarOpened ? 65 : 'auto',
            top: !sidebarOpened ? 28 : 'auto',

            opacity: showExpandCollapseButton ? 1 : 0,
            visibility: showExpandCollapseButton ? 'visible' : 'hidden',

            // Collapse button fades in when sidebar is closed
            transition:
              showExpandCollapseButton && !sidebarOpened
                ? 'opacity 0.3s ease-in'
                : 'none',
          }}
        />
      </Flex>
    </Flex>
  );
};

export default SidebarHeader;
