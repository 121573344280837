// ---
// ALL process.env usage for the application should be in this file.
// If any process.env usage is provided in any other file in the src
// directory, it will not be replaced.
//
// In dev, process.env will be used.
// On build/deploy built files, globals will be injected into index.html
// That is why `window.` is the preferred way to grab these constants with
// a backup to process.env
//
// To properly integrate env vars, you MUST provide both `window.` and `process.env.` load options:
// export const MY_ENV_NAME = window.MY_ENV_NAME || process.env.MY_ENV_NAME;
// ---
export const REACT_APP_DEBUG =
  window.REACT_APP_DEBUG || process.env.REACT_APP_DEBUG;
export const REACT_APP_BASE_DOMAIN =
  window.REACT_APP_BASE_DOMAIN || process.env.REACT_APP_BASE_DOMAIN;
export const REACT_APP_ENV_NAME =
  window.REACT_APP_ENV_NAME || process.env.REACT_APP_ENV_NAME;
export const NODE_ENV = window.NODE_ENV || process.env.NODE_ENV;
export const REACT_APP_TEST_SUBDOMAIN =
  window.REACT_APP_TEST_SUBDOMAIN || process.env.REACT_APP_TEST_SUBDOMAIN;
export const REACT_APP_BACKEND_BASE_DOMAIN =
  window.REACT_APP_BACKEND_BASE_DOMAIN ||
  process.env.REACT_APP_BACKEND_BASE_DOMAIN;
export const REACT_APP_API_BASE_DOMAIN =
  window.REACT_APP_API_BASE_DOMAIN || process.env.REACT_APP_API_BASE_DOMAIN;
export const REACT_APP_SUBSCRIPTIONS_API_BASE_DOMAIN =
  window.REACT_APP_SUBSCRIPTIONS_API_BASE_DOMAIN ||
  process.env.REACT_APP_SUBSCRIPTIONS_API_BASE_DOMAIN;
export const REACT_APP_VISIBILITY_API_BASE_DOMAIN =
  window.REACT_APP_VISIBILITY_API_BASE_DOMAIN ||
  process.env.REACT_APP_VISIBILITY_API_BASE_DOMAIN;
export const REACT_APP_INTEGRATIONS_BACKEND_API_BASE_DOMAIN =
  window.REACT_APP_INTEGRATIONS_BACKEND_API_BASE_DOMAIN ||
  process.env.REACT_APP_INTEGRATIONS_BACKEND_API_BASE_DOMAIN;
export const REACT_APP_INTEGRATIONS_DEVICE_TRUST_API_BASE_DOMAIN =
  window.REACT_APP_INTEGRATIONS_DEVICE_TRUST_API_BASE_DOMAIN ||
  process.env.REACT_APP_INTEGRATIONS_DEVICE_TRUST_API_BASE_DOMAIN;
export const REACT_APP_AUDIT_EVENTS_API_BASE_DOMAIN =
  window.REACT_APP_AUDIT_EVENTS_API_BASE_DOMAIN ||
  process.env.REACT_APP_AUDIT_EVENTS_API_BASE_DOMAIN;
export const REACT_APP_COMPLIANCE_API_BASE_DOMAIN =
  window.REACT_APP_COMPLIANCE_API_BASE_DOMAIN ||
  process.env.REACT_APP_COMPLIANCE_API_BASE_DOMAIN;
export const REACT_APP_AGENT_LOGS_API_BASE_DOMAIN =
  window.REACT_APP_AGENT_LOGS_API_BASE_DOMAIN ||
  process.env.REACT_APP_AGENT_LOGS_API_BASE_DOMAIN;
export const REACT_APP_AUTH0_DOMAIN =
  window.REACT_APP_AUTH0_DOMAIN || process.env.REACT_APP_AUTH0_DOMAIN;
export const REACT_APP_AUTH0_ENROLLMENT_AUDIENCE =
  window.REACT_APP_AUTH0_ENROLLMENT_AUDIENCE ||
  process.env.REACT_APP_AUTH0_ENROLLMENT_AUDIENCE;
export const REACT_APP_AUTH0_PASSPORT_AUDIENCE =
  window.REACT_APP_AUTH0_PASSPORT_AUDIENCE ||
  process.env.REACT_APP_AUTH0_PASSPORT_AUDIENCE;
export const REACT_APP_AUTH0_WEB_API_AUDIENCE =
  window.REACT_APP_AUTH0_WEB_API_AUDIENCE ||
  process.env.REACT_APP_AUTH0_WEB_API_AUDIENCE;
export const REACT_APP_CLIENT_DOMAIN =
  window.REACT_APP_CLIENT_DOMAIN || process.env.REACT_APP_CLIENT_DOMAIN;
export const REACT_APP_BACKEND_PROTOCOL =
  window.REACT_APP_BACKEND_PROTOCOL || process.env.REACT_APP_BACKEND_PROTOCOL;
export const REACT_APP_LAUNCH_DARKLY_CLIENT_ID =
  window.REACT_APP_LAUNCH_DARKLY_CLIENT_ID ||
  process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID;
export const REACT_APP_FAST_REFRESH =
  window.REACT_APP_FAST_REFRESH || process.env.REACT_APP_FAST_REFRESH;
export const REACT_APP_DATADOG_APP_ID =
  window.REACT_APP_DATADOG_APP_ID || process.env.REACT_APP_DATADOG_APP_ID;
export const REACT_APP_DATADOG_CLIENT_TOKEN =
  window.REACT_APP_DATADOG_CLIENT_TOKEN ||
  process.env.REACT_APP_DATADOG_CLIENT_TOKEN;
export const REACT_APP_DATADOG_ENV =
  window.REACT_APP_DATADOG_ENV || process.env.REACT_APP_DATADOG_ENV;
export const REACT_APP_APPLE_MAPS_JWT =
  window.REACT_APP_APPLE_MAPS_JWT || process.env.REACT_APP_APPLE_MAPS_JWT;
export const REACT_APP_WEB_GATEWAY_DOMAIN =
  window.REACT_APP_WEB_GATEWAY_DOMAIN ||
  process.env.REACT_APP_WEB_GATEWAY_DOMAIN;
export const REACT_APP_PRODUCT_UPDATES_DOMAIN =
  window.REACT_APP_PRODUCT_UPDATES_DOMAIN ||
  process.env.REACT_APP_PRODUCT_UPDATES_DOMAIN;
