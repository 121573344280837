/* istanbul ignore file */
import { apiTypes } from '../../common';
import appStoreApps from './app-store-apps';
import autoApps from './auto-apps';
import customApps from './custom';
import enrollmentApps from './enrollment';
import ipaApps from './in-house-apps';
import operatingSystemApps from './operating-systems';
import platformApps from './platform-apps';
import profileApps from './profile';
import securityApps from './security';

/**
 * Library Item configurations are split up into logical groupings.
 */
const allLibraryItemsConfiguration = /** @type {const} */ {
  ...appStoreApps,
  ...autoApps,
  ...customApps,
  ...enrollmentApps,
  ...ipaApps,
  ...operatingSystemApps,
  ...profileApps,
  ...securityApps,
  ...platformApps,
};

const getConfig = (key) => allLibraryItemsConfiguration[key];

export const getConfigbyRouteType = (routeType) =>
  Object.values(allLibraryItemsConfiguration).find(
    (d) => d.routeType === routeType,
  );

export const getLibraryItemConfig = (item) =>
  Object.values(allLibraryItemsConfiguration).find((d) => {
    if (d.type === apiTypes.PROFILE) {
      return d.type === item.type && d.identifier === item.identifier;
    }
    return d.type === item?.type;
  });

export default allLibraryItemsConfiguration;
export {
  appStoreApps,
  autoApps,
  customApps,
  enrollmentApps,
  getConfig,
  ipaApps,
  operatingSystemApps,
  profileApps,
  securityApps,
};
