/* istanbul ignore file */
import {
  Box,
  Button,
  DropdownMenuPrimitives as DropdownMenu,
  Flex,
  Heading,
  Icon,
  Loader,
  Pagination,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@kandji-inc/nectar-ui';
import { keepPreviousData } from '@tanstack/react-query';
import deepcopy from 'deepcopy';
import React, { useContext, useMemo, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
  SIDEBAR_CLOSED_OFFSET,
  SIDEBAR_OPENED_OFFSET,
  links,
} from 'src/app/common/constants';
import { usePagination } from 'src/components/ui';
import { InterfaceContext } from 'src/contexts/interface';
import { noParameters } from 'src/features/blueprint-flow/assets';
import DateFilter from 'src/features/blueprint-flow/pages/activity/components/DateFilter';
import useSort, {
  getSortParam,
} from 'src/features/blueprint-flow/pages/devices/use-sort';
import useParameters from 'src/features/blueprint-flow/services/use-parameters';
import { paths } from 'src/features/blueprints/common';
import { MultiSelectFilter } from 'src/features/device-status-tab/search-filters';
import useAdjustSidebarChatBubble from 'src/features/util/hooks/use-adjust-sidebar-chat-bubble';
import { get } from 'src/features/util/lib';
import { useBlueprints } from 'src/features/visibility/prism/hooks';
import { NULL_VALUE_N_DASH } from 'src/features/visibility/prism/utils/column-utils';
import { useGetSearchParams } from 'src/hooks/useGetSearchParams';
import { i18n } from 'src/i18n';
import { getParameterHtml } from '../common/helpers';
import useParamHistory from './useParamHistory';

const getColumns = () => [
  {
    name: '',
    dataKey: '',
    meta: {
      header: {
        css: {
          width: 15,
          $$width: '15px',
          $$thWidth: '15px',
          $$cellWidth: '15px',
        },
        row: {
          css: {
            boxShadow: 'none',
          },
        },
      },
    },
    cell: ({ isExpanded }) => (
      <Icon
        name={isExpanded ? 'fa-angle-up-small' : 'fa-angle-down-small'}
        size="sm"
        color="var(--color-neutral-80)"
      />
    ),
    isSortable: false,
  },
  {
    name: i18n.t('Name'),
    dataKey: 'parameter_name',
    cell: ({ history, dataKey }) => (
      <Text css={{ fontWeight: 500 }}>{history[dataKey]}</Text>
    ),
    isSortable: false,
  },
  {
    name: i18n.t('Blueprint'),
    dataKey: 'blueprint',
    cell: ({ history }) =>
      history.blueprint ? (
        <Link
          to={paths.getBlueprintRouteByType(history.blueprint)}
          style={{ color: 'inherit', display: 'block', width: 'fit-content' }}
          onClick={(e) => e.stopPropagation()}
        >
          <Text>{history.blueprint.name}</Text>
        </Link>
      ) : (
        <Text>{NULL_VALUE_N_DASH}</Text>
      ),
    isSortable: true,
  },
  {
    name: i18n.t('Date'),
    dataKey: 'ended_at',
    cell: ({ history, dataKey }) => (
      <Text>{i18n.format.datetime(history[dataKey])}</Text>
    ),
    isSortable: true,
  },
  {
    name: 'action',
    dataKey: '',
    meta: {
      header: {
        css: {
          width: 20,
        },
      },
      row: {
        css: {
          $$tdTruncate: 'none',
        },
      },
    },
    headerCell: () => null,
    cell: ({ history, onGoToNotes }) => (
      <DropdownMenu.Root modal={false}>
        <DropdownMenu.Trigger asChild onClick={(e) => e.stopPropagation()}>
          <Button
            variant="subtle"
            compact
            icon={{ name: 'ellipsis-vertical' }}
          />
        </DropdownMenu.Trigger>
        <DropdownMenu.Content
          css={{
            zIndex: 3,
            marginBottom: '20px',
          }}
          align="end"
          side="bottom"
        >
          <DropdownMenu.Item
            asChild
            onClick={() => {
              if (onGoToNotes) {
                const html = getParameterHtml(
                  history.parameter_name,
                  history.run,
                  history,
                );
                onGoToNotes(html);
              }
            }}
          >
            <Flex alignItems="center" gap="sm">
              <Icon name="note" size="sm" />
              <Text>{i18n.t('Create Note')}</Text>
            </Flex>
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu.Root>
    ),
    isSortable: false,
  },
];

const getStatusList = () => [
  {
    value: 'PASS',
    label: i18n.t('Passed'),
  },
  {
    value: 'REMEDIATED',
    label: i18n.t('Remediated'),
  },
  {
    value: 'WARNING,ERROR',
    label: i18n.t('Alert'),
  },
  {
    value: 'MUTE',
    label: i18n.t('Muted Alert'),
  },
  {
    value: 'INCOMPATIBLE',
    label: i18n.t('Incompatible'),
  },
  {
    value: 'NO_HISTORY',
    label: i18n.t('Not Yet Run'),
  },
];

const getDefaultFilter = () => ({
  statuses: getStatusList().map(({ value }) => value),
  period: {
    enumValue: 'ALL',
    dateRange: {
      from: null,
      to: null,
    },
  },
});

const ParameterHistory = (props) => {
  useAdjustSidebarChatBubble();
  const { onGoToNotes } = props;
  const { sidebarOpened } = useContext(InterfaceContext);
  const history = useHistory();
  const { id: computerId } = useParams<{ id: string }>();
  const [parameterId] = useGetSearchParams('parameter');

  const defaultFilter = getDefaultFilter();
  const statuses = getStatusList();
  const [filter, setFilter] = useState(deepcopy(defaultFilter));
  const [sort, onSortColumn] = useSort({ id: 'ended_at', state: 'desc' });
  const { paginationState, setPagination } = usePagination();
  const [expanded, setExpanded] = useState([]);

  const isFilterActive = useMemo(
    () => JSON.stringify(filter) !== JSON.stringify(defaultFilter),
    [filter],
  );

  const isAllStatuses = filter.statuses.length === statuses.length;
  const isAllTime = filter.period.enumValue === 'ALL';
  const isCustomDate = filter.period.enumValue === 'custom_date_range';
  const isCustomDateSet =
    filter.period.dateRange.from !== null &&
    filter.period.dateRange.to !== null;

  const { data: parametersConfig, isLoading: isLoadingParameters } =
    useParameters();
  const { data: lastCheckinData } = useParamHistory(
    parameterId,
    computerId,
    {
      displayType: 'STATUS_CHANGE',
      ordering: '-ends_at',
    },
    Boolean(parameterId),
  );

  const {
    data: paramHistoryData,
    isLoading: isLoadingParamHistory,
    isRefetching: isRefetchingParamHistory,
  } = useParamHistory(
    parameterId,
    computerId,
    {
      displayType: 'STATUS_CHANGE',
      page: paginationState.pageIndex + 1,
      sizePerPage: paginationState.pageSize,
      ordering: getSortParam(sort.id, sort.state),
      ...(isAllTime || (isCustomDate && !isCustomDateSet)
        ? {}
        : {
            period: isCustomDate
              ? `${new Date(
                  filter.period.dateRange.from,
                ).toISOString()}@${new Date(
                  new Date(filter.period.dateRange.to).getTime() - 1,
                ).toISOString()}`
              : filter.period.enumValue,
          }),
      ...(isAllStatuses ? {} : { status: filter.statuses.join(',') }),
    },
    Boolean(parameterId),
    {
      placeholderData: keepPreviousData({
        count: 0,
        results: [],
      }),
    },
  );
  const { data: blueprints } = useBlueprints();

  if (isLoadingParameters) {
    return (
      <Flex
        alignItems="center"
        justifyContent="center"
        css={{ height: '500px' }}
      >
        <Loader />
      </Flex>
    );
  }

  const columns = getColumns();
  const parameter = parametersConfig.parameters.parametersById[parameterId];
  const lastCheckIn = (lastCheckinData as any)?.results[0]?.ended_at;
  const results = (paramHistoryData as any)?.results || [];
  const parameterHistory = results.map((history) => ({
    ...history,
    blueprint: blueprints?.find(({ id }) => id === history.blueprint_id),
  }));
  const isLoading = isLoadingParamHistory || isRefetchingParamHistory;
  const currentPage = paginationState.pageIndex + 1;
  const count = (paramHistoryData as any)?.count || 0;
  const totalPages = Math.ceil(count / paginationState.pageSize);

  return (
    <Box py3>
      <Box mb4>
        <Button
          compact
          variant="subtle"
          icon={{ name: 'arrow-left' }}
          onClick={() => history.push(`${links.devices}/${computerId}/status`)}
        >
          {i18n.t('Back')}
        </Button>
        <Heading size="2">{i18n.t('Parameter History')}</Heading>
      </Box>
      <Heading size="4">{parameter.name}</Heading>
      <Text size="2" variant="secondary">
        {i18n.t('Last Check-In: {time}', {
          time: lastCheckIn
            ? i18n.format.datetime(lastCheckIn)
            : NULL_VALUE_N_DASH,
        })}
      </Text>

      <Flex flow="column" gap="md" css={{ marginTop: '$3' }}>
        <Flex alignItems="center" pt3 pb3 gap="md">
          <DateFilter
            onChange={(updatedValue) =>
              setFilter((prev) => {
                if (updatedValue.enumValue !== 'custom_date_range') {
                  return {
                    ...prev,
                    period: {
                      ...updatedValue,
                      dateRange: { from: null, to: null },
                    },
                  };
                }

                return { ...filter, period: updatedValue };
              })
            }
            period={filter.period}
            defaultFilter={defaultFilter.period}
          />
          <MultiSelectFilter
            name={i18n.t('Status')}
            options={statuses}
            value={filter.statuses}
            setValue={(selected) =>
              // @ts-ignore
              setFilter((prev) => ({ ...prev, statuses: [...selected] }))
            }
            defaultOptions={statuses}
          />
          {isFilterActive && (
            <Button
              variant="subtle"
              compact
              onClick={() => setFilter(deepcopy(defaultFilter))}
            >
              {i18n.t('Clear all')}
            </Button>
          )}
        </Flex>
      </Flex>

      <Box pt3>
        <Table aria-label="device-table">
          <Thead
            style={{
              position: 'sticky',
              top: 0,
            }}
          >
            <Tr>
              {columns.map((column) => {
                const HeaderCell = column.headerCell;
                const isSortColumn = sort.id === column.dataKey;
                const state = isSortColumn ? sort.state : 'none';
                const onSort = () =>
                  isSortColumn
                    ? onSortColumn()
                    : onSortColumn(column.dataKey, 'asc');
                return (
                  <Th
                    key={column.name}
                    title={null}
                    css={column.meta?.header?.css}
                    sort={
                      column.isSortable && {
                        state,
                        onSort,
                      }
                    }
                    showMenu
                  >
                    {HeaderCell ? (
                      <HeaderCell />
                    ) : (
                      <Text
                        size="1"
                        variant="secondary"
                        css={{ fontWeight: 500 }}
                      >
                        {column.name}
                      </Text>
                    )}
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody data-testid="am-device-tbody">
            {isLoading && (
              <Tr>
                <Td colSpan={4}>
                  <Flex
                    hFull
                    flow="column"
                    alignItems="center"
                    justifyContent="center"
                    data-testid="am-device-empty"
                  >
                    <Loader />
                  </Flex>
                </Td>
              </Tr>
            )}
            {!isLoading &&
              parameterHistory.map((history) => {
                const isExpanded = expanded.includes(history.id);

                return (
                  <>
                    <Tr
                      css={{ cursor: 'pointer' }}
                      key={history.id}
                      onClick={() =>
                        setExpanded(
                          isExpanded
                            ? expanded.filter((row) => row !== history.id)
                            : [...expanded, history.id],
                        )
                      }
                    >
                      {columns.map((column) => {
                        const Cell = column.cell;
                        const value = get<string | any>(
                          history,
                          column.dataKey,
                        );
                        return (
                          <Td
                            key={column.name}
                            title={null}
                            css={{
                              $$tdActiveBg: 'transparent',
                              ...(column.meta?.row?.css || {}),
                            }}
                          >
                            {Cell ? (
                              <Cell
                                history={history}
                                isExpanded={isExpanded}
                                onGoToNotes={onGoToNotes}
                                {...column}
                              />
                            ) : (
                              value || NULL_VALUE_N_DASH
                            )}
                          </Td>
                        );
                      })}
                    </Tr>
                    {isExpanded && (
                      <Tr key={`expanded_${history.id}`} hoverBg="none">
                        <Td
                          title="expand toggle"
                          css={{
                            $$tdActiveBg: 'transparent',
                          }}
                        >
                          &nbsp;
                        </Td>
                        <Td
                          title="Expanded content"
                          colSpan={4}
                          css={{
                            $$tdActiveBg: 'transparent',
                            color: '$neutral80',
                          }}
                        >
                          <Flex flow="column" gap="md">
                            <Text>
                              {i18n.t('Last Update: {time}', {
                                time: i18n.format.datetime(history.ended_at),
                              })}
                            </Text>
                            {history.details?.map((detail, idx) => (
                              <Text key={idx}>{detail}</Text>
                            ))}
                          </Flex>
                        </Td>
                      </Tr>
                    )}
                  </>
                );
              })}
            {!isLoading && !parameterHistory.length && (
              <Tr>
                <Td colSpan={4} css={{ paddingTop: '12px' }}>
                  <Flex
                    hFull
                    flow="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Box mb4>{noParameters}</Box>
                    <Flex
                      flow="column"
                      alignItems="center"
                      mb4
                      css={{ maxWidth: '532px' }}
                    >
                      <Heading size="4">{i18n.t('No results found')}</Heading>
                      <Text>
                        {i18n.t(
                          "We couldn't find a match. Try changing your filter parameters.",
                        )}
                      </Text>
                    </Flex>
                  </Flex>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Box>
      <Box
        css={{
          position: 'fixed',
          bottom: 0,
          left: sidebarOpened
            ? `${SIDEBAR_OPENED_OFFSET}px`
            : `${SIDEBAR_CLOSED_OFFSET}px`,
          width: sidebarOpened
            ? `calc(100% - ${SIDEBAR_OPENED_OFFSET}px)`
            : `calc(100% - ${SIDEBAR_CLOSED_OFFSET}px)`,
          padding: '$3 $5',
          borderTop: '1px solid $neutral20',
          backgroundColor: '$neutral0',
        }}
      >
        <Pagination
          menuAbove
          currentPage={currentPage}
          totalPages={totalPages || 1}
          totalItems={count}
          itemsPerPage={paginationState.pageSize}
          onPageChange={(page) =>
            setPagination((prev) => ({
              ...prev,
              pageIndex: page - 1,
            }))
          }
          data-testid="am-activity-pagination"
        />
      </Box>
    </Box>
  );
};

export default ParameterHistory;
