import {
  DropdownMenuPrimitives as Dropdown,
  Flex,
  Icon,
  Text,
} from '@kandji-inc/nectar-ui';

type OpenSidebarProfileDropdownTriggerProps = {
  isDropdownOpen: boolean;
  userFirstName?: string;
  userLastName?: string;
  userEmail?: string;
};

export const OpenSidebarProfileDropdownTrigger = ({
  isDropdownOpen,
  userFirstName,
  userLastName,
  userEmail,
}: OpenSidebarProfileDropdownTriggerProps) => (
  <Dropdown.Trigger asChild data-testid="sidebar-user-name">
    <Flex
      alignItems="center"
      justifyContent="space-between"
      wFull
      p2
      css={{
        height: '52px',
        border: '1px solid transparent',
        cursor: 'pointer',
        backgroundColor: isDropdownOpen ? '#EAEAEA' : 'transparent',
        borderRadius: 'var(--radii-1)',

        '&:hover': {
          backgroundColor: '#EAEAEA',
        },

        '&:active': {
          border: '1px solid var(--colors-primary_focus_ring)',
        },
      }}
    >
      <Flex flow="column">
        <Text
          variant="secondary"
          size={2}
          css={{
            fontWeight: '$medium',
            maxWidth: '184px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {`${userFirstName} ${userLastName}`}
        </Text>
        <Text variant="secondary" size={1}>
          {userEmail}
        </Text>
      </Flex>
      <Icon name="chevrons-up-down" size="sm" />
    </Flex>
  </Dropdown.Trigger>
);
