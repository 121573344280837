/* istanbul ignore file */
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import React, { Suspense, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Route as BaseRoute, Redirect, Router, Switch } from 'react-router-dom';

const Blueprints = React.lazy(() => import('features/blueprints'));
const Integrations = React.lazy(() => import('features/integrations/routes'));
const LibraryItems = React.lazy(() => import('features/library-items/routes'));
const GSuiteUserList = React.lazy(() => import('../components/GSuiteUserList'));
const UserList = React.lazy(() => import('features/user-list'));
const ThreatList = React.lazy(() => import('src/features/edr/threat'));
const ActivityTab = React.lazy(() => import('src/features/ActivityTab'));
const ActivityTabNew = React.lazy(() => import('../components/ActivityTabNew'));
const AlertsList = React.lazy(() => import('../components/AlertsList'));
const AddDevices = React.lazy(() => import('../../pages/add-devices'));
const SettingsNew = React.lazy(() => import('../../pages/settings-new')); // todo: cleanup name and routing after 'Integrations' UI overhaul
const Resources = React.lazy(() => import('../../pages/resources'));
const PassportAuthentication = React.lazy(
  () => import('src/passport/pages/Authentication'),
);
const PassportAuthenticationSuccess = React.lazy(
  () => import('src/passport/pages/Success'),
);
const EnrollmentRouter = React.lazy(() => import('src/features/enrollment'));

import { useFlags } from 'src/config/feature-flags';
import { AccountContext, usePermissions } from 'src/contexts/account';
import { DataExportProvider } from 'src/contexts/data-export/data-export-context';
import AgentLogs from 'src/features/agent-logs/agent-logs';
import history from './history';

import {
  CreatePulseCheck,
  PulseCheckDetails,
} from 'src/features/visibility/pulse';
import { SearchTest } from 'src/pages/search-test';

import AgentEnrollmentAuthentication from 'src/agent/pages/enrollmentAuthentication';
import { getDevelopmentEnvironmentName, getEnv } from 'src/util';
import ADCSEnrollmentAuthentication from '../../adcs/pages/enrollmentAuthentication';
import { useAuthenticationRequired } from '../../auth0';
import MainWithAuthCheck from '../../components/Main';
import Whoops404 from '../../pages/404';
import ForgotPassword from '../../pages/forgot-password';
import Registration from '../../pages/registration';
import ResetPassword from '../../pages/reset-password';
import SignIn, { RedirectToAuth0SigninFlow } from '../../pages/signin';
import UserProfile from '../../pages/user-profile';
import { FLAGS, links } from '../common/constants';

import { ShouldProvisionProvider } from 'src/contexts/should-provision';
import { MainVulnerabilityPage } from 'src/features/edr/vulnerability';
import TrialDashboard from 'src/features/trial-dashboard';
import Visibility from 'src/features/visibility/prism/Visibility';
import ComputerList from '../components/ComputerList';
import SingleDevicePage from '../components/SingleDevicePage';
import GSuiteUserRecord from '../components/gSuiteUsers/GSuiteUserRecord';
import {
  LineLoader,
  LineLoaderSimple,
} from '../components/interface/LineLoader';
import UiSnackbar from '../components/interface/Snackbar';

export const queryClient = new QueryClient({
  defaultOptions: {
    // More details: https://tanstack.com/query/v4/docs/react/guides/important-defaults
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
});

const PublicRoute = ({ component: Component, profile = null, ...rest }) => (
  <BaseRoute
    {...rest}
    render={(props) => (
      <Suspense fallback={<LineLoaderSimple />}>
        <Component {...props} profile={profile} />
      </Suspense>
    )}
  />
);

const AuthenticatedRoute = ({
  component: Component,
  profile,
  shouldRender = true,
  redirectOnFail = '/devices',
  ...rest
}) => {
  useAuthenticationRequired({
    returnTo: () => window.location.pathname,
  });

  if (!shouldRender) {
    if (redirectOnFail) {
      return <Redirect to={redirectOnFail} />;
    }
    return null;
  }
  return (
    <ShouldProvisionProvider>
      <BaseRoute
        {...rest}
        render={(props) => (
          <Suspense fallback={<LineLoader />}>
            <Component {...props} profile={profile} />
          </Suspense>
        )}
      />
    </ShouldProvisionProvider>
  );
};

// settings routes i.e /my-company
const AdminRoute = ({ user, component: Component, ...rest }) => {
  useAuthenticationRequired({
    returnTo: () => window.location.pathname,
  });
  const permissions = usePermissions();

  return (
    <BaseRoute
      {...rest}
      render={(props) => {
        const { pathname } = props.location;
        if (
          pathname.indexOf('/my-company') > -1 &&
          !permissions.canManageSettings
        ) {
          return (
            <Redirect
              to={{ pathname: links.index, state: { from: props.location } }}
            />
          );
        }
        return (
          <Suspense fallback={<LineLoader />}>
            <Component {...props} />
          </Suspense>
        );
      }}
    />
  );
};

export const Routes = ({ user }) => {
  const { currentCompany } = useContext(AccountContext);
  const {
    'visibility-fleet-attributes': ffVisibility,
    'visibility-pulse-checks': ffPulseChecks,
    'bzbe-04122024-agent-log-ingestion': ffAgentLogs,
    'rd-plg-trial-dashboard': ffTrialDashboard,
    'dc-exp-auth0-redirect-signin-flow': ffAuth0RedirectSigninFlow,
    'edr_110824_edr-trial-enabled-ui': ffEdrTrial,
    'dc-1146-passport-google-workspace': LDFF_passportGoogleWorkspace,
    'dexi-12042024-modern-users-page': ffModernUsersPage,
    'insp-01282025-new-activity-page': ffNewActivityPage,
    [FLAGS.ORANGE_AXP_WXP]: enabledPlatforms = { axp: false, wxp: false },
  } = useFlags([
    'visibility-fleet-attributes',
    'visibility-pulse-checks',
    'bzbe-04122024-agent-log-ingestion',
    'rd-plg-trial-dashboard',
    'edr_110824_edr-trial-enabled-ui',
    'dexi-12042024-modern-users-page',
    'insp-01282025-new-activity-page',
    FLAGS.ORANGE_AXP_WXP,
  ]);

  const { axp, wxp } = enabledPlatforms;

  const {
    feature_configuration,
    billing_type,
    onboarding = {},
  } = currentCompany;
  const { canViewPulse } = usePermissions();
  const clientId = useSelector<{ auth0: { clientId: string } }, string>(
    ({ auth0 }) => auth0.clientId,
  );

  const isEdrSkuEnabled = !!feature_configuration?.edr?.enabled;

  // const isTrial =
  //   billing_type === 'automated_trial' && Object.keys(onboarding).length > 0;
  const isTrial =
    billing_type === 'automated_trial' || billing_type === 'trial';

  return (
    <QueryClientProvider client={queryClient}>
      <Switch>
        {/* Public Routes For Agent */}
        <PublicRoute
          path={links.agentEnrollment}
          component={AgentEnrollmentAuthentication}
        />
        {/* Public Routes For ADCS app */}
        <PublicRoute
          path={links.adcsEnrollment}
          component={ADCSEnrollmentAuthentication}
        />
        <PublicRoute
          exact
          path={links.passportAuthentication}
          component={PassportAuthentication}
          shouldRender={LDFF_passportGoogleWorkspace}
        />
        <PublicRoute
          exact
          path={links.passportAuthenticationSuccess}
          component={PassportAuthenticationSuccess}
          shouldRender={LDFF_passportGoogleWorkspace}
        />
        <PublicRoute
          path={links.signin}
          component={
            ffAuth0RedirectSigninFlow ? RedirectToAuth0SigninFlow : SignIn
          }
        />
        <PublicRoute path={links.registration} component={Registration} />
        <PublicRoute path={links.forgotPassword} component={ForgotPassword} />
        <PublicRoute path={links.resetPassword} component={ResetPassword} />

        <BaseRoute
          path="/enroll"
          render={() => <EnrollmentRouter auth0ClientId={clientId} />}
        />

        <MainWithAuthCheck>
          <DataExportProvider>
            <Switch>
              <AuthenticatedRoute
                exact
                path={[
                  ...(ffTrialDashboard && isTrial ? [links.index] : []),
                  links.trial,
                ]}
                shouldRender={ffTrialDashboard && isTrial}
                component={TrialDashboard}
              />
              <AuthenticatedRoute
                exact
                path={links.threat}
                component={ThreatList}
                shouldRender={
                  (!isEdrSkuEnabled && ffEdrTrial) || isEdrSkuEnabled
                }
              />
              <AuthenticatedRoute
                path={links.vulnerability}
                component={MainVulnerabilityPage}
              />
              <AuthenticatedRoute
                exact
                path={[
                  ...(!(ffTrialDashboard && isTrial) ? [links.index] : []),
                  links.computers,
                  links.devices,
                  `${links.devices}/auto-enroll`,
                ]}
                component={ffVisibility ? Visibility : ComputerList}
              />
              <AuthenticatedRoute
                exact
                path={[links.pulse, `${links.pulse}/new`]}
                component={Visibility}
                shouldRender={canViewPulse && !!ffVisibility && !!ffPulseChecks}
              />
              <AuthenticatedRoute
                exact
                path={[`${links.pulse}/new/:pulseType`]}
                component={CreatePulseCheck}
                shouldRender={canViewPulse && !!ffVisibility && !!ffPulseChecks}
              />
              <AuthenticatedRoute
                exact
                path={[links.prism, `${links.prism}/:prismCategory`]}
                component={Visibility}
                shouldRender={!!ffVisibility}
              />
              <AuthenticatedRoute
                exact
                path={[`${links.pulse}/:pulseJobId`]}
                component={PulseCheckDetails}
                shouldRender={canViewPulse && !!ffVisibility && !!ffPulseChecks}
              />
              <AuthenticatedRoute
                exact
                path={[links.depDevices]}
                component={ComputerList}
              />
              <AuthenticatedRoute
                path={[
                  `${links.computers}/:id/:tab?`,
                  `${links.devices}/:id/:tab?`,
                ]}
                component={SingleDevicePage}
              />
              <AuthenticatedRoute path="/alerts/:tab?" component={AlertsList} />
              <AuthenticatedRoute path="/blueprints" component={Blueprints} />
              <AuthenticatedRoute path="/add-devices" component={AddDevices} />
              <AuthenticatedRoute
                path="/users/all/:user_id"
                component={GSuiteUserRecord}
              />
              <AuthenticatedRoute
                path={ffModernUsersPage ? links.users : '/users/:tab?'}
                component={ffModernUsersPage ? UserList : GSuiteUserList}
              />
              <AuthenticatedRoute
                path={links.activity}
                component={ffNewActivityPage ? ActivityTab : ActivityTabNew}
              />
              <AuthenticatedRoute
                path={links.integrations.root}
                component={Integrations}
              />
              <AuthenticatedRoute
                path="/user-profile"
                component={UserProfile}
              />
              <AuthenticatedRoute path="/library" component={LibraryItems} />
              <AdminRoute
                path="/my-company"
                user={user}
                component={SettingsNew}
              />
              <AuthenticatedRoute path="/search-test" component={SearchTest} />
              <AuthenticatedRoute
                path={[links.resources]}
                component={Resources}
              />

              {/* Dev Demo Only */}
              <AuthenticatedRoute
                exact
                path={[links.logs, `${links.logs}/events`]}
                component={AgentLogs}
                shouldRender={!!ffAgentLogs && getEnv() === 'dev'}
              />

              <AuthenticatedRoute component={Whoops404} />
            </Switch>

            <UiSnackbar />
            <ReactQueryDevtools
              initialIsOpen={false}
              buttonPosition="bottom-left"
            />
          </DataExportProvider>
        </MainWithAuthCheck>
      </Switch>
    </QueryClientProvider>
  );
};

const AuthRouter = () => {
  const user = useSelector(({ account }) => account.user);

  if (
    window.location.pathname === links.signup &&
    !getDevelopmentEnvironmentName()
  ) {
    window.location = 'https://kandji.io';
    return null;
  }

  return (
    <Router history={history}>
      <Routes user={user} />
    </Router>
  );
};

export default AuthRouter;
