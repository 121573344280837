import {
  Button,
  Chip,
  Dialog,
  Flex,
  MultiSelect,
  Text,
  useToast_UNSTABLE as useToast,
} from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import React from 'react';

import {
  SIDEBAR_CLOSED_OFFSET,
  SIDEBAR_OPENED_OFFSET,
} from 'src/app/common/constants';
import { InterfaceContext } from 'src/contexts/interface';
import { useGetComputerTags } from '../use-get-computer-tags';
import { useTags } from '../use-tags';
import { useUpdateComputerTags } from '../use-update-computer-tags';

const BulkEditComputersModal = (props: any) => {
  const { onHide, computerIds } = props;
  const { toast } = useToast();
  const { sidebarOpened } = React.useContext(InterfaceContext);

  const [searchTerm, setSearchTerm] = React.useState('');
  const [toAddTags, setToAddTags] = React.useState([]);
  const [toRemoveTags, setToRemoveTags] = React.useState([]);
  const [availableTags, setAvailableTags] = React.useState([]);

  const { data: computerTagsRes, isLoading: isLoadingComputerTags } =
    useGetComputerTags(computerIds);
  const { getTags } = useTags();
  const { data: tagsRes } = getTags(searchTerm);
  const {
    mutateAsync: updateComputersTags,
    isPending: isUpdatingComputersTags,
  } = useUpdateComputerTags();
  const tags = tagsRes?.results || [];
  const computerTags = computerTagsRes?.results || [];

  const toastStyle: React.CSSProperties = {
    left: /* istanbul ignore next */ sidebarOpened
      ? `${SIDEBAR_OPENED_OFFSET + 12}px`
      : `${SIDEBAR_CLOSED_OFFSET + 12}px`,
    bottom: '12px',
    position: 'absolute',
  };

  React.useEffect(() => {
    if (tags.length) {
      setAvailableTags(
        tags?.map(({ name }) => ({
          label: name,
          value: name,
          richLabel: <Chip label={name} />,
        })) || [],
      );
    }
  }, [tags]);

  if (isLoadingComputerTags) {
    return null;
  }

  const hasAnyTagsSelected = Boolean(toAddTags.length || toRemoveTags.length);
  const anyComputerHasTags = Boolean(computerTags.length);
  const commonTagsCounts = computerTags.reduce(
    (a, c) => ({
      ...a,
      [c.name]: c.computer_count,
    }),
    {},
  );

  const commonTagsWithoutToAdd = computerTags
    .filter(({ name }) => !toAddTags.includes(name))
    .map(({ name }) => ({
      label: name,
      value: name,
      richLabel: (
        <Flex
          justifyContent="space-between"
          alignItems="center"
          css={{ width: '100%' }}
        >
          <Chip label={name} />
          <Text size="1" variant="description" css={{ color: '$neutral70' }}>
            {i18n.common.numDevices(commonTagsCounts[name])}
          </Text>
        </Flex>
      ),
    }));

  const content = (
    <Flex flow="column" css={{ gap: '28px' }}>
      <MultiSelect
        label={i18n.t('Add tags')}
        value={toAddTags}
        onChange={(t) => {
          setToAddTags([...t]);
          setToRemoveTags((prev) => prev.filter((tag) => !t.includes(tag)));
        }}
        options={availableTags}
        placeholder={i18n.t('Enter tags')}
        searchable
        searchFn={setSearchTerm}
        hideNoOptionsFoundMessage={true}
        creatable={{
          active: false,
          onCreate: (tag) => setToAddTags((prev) => [...prev, tag]),
          showMenuMessage: true,
          maxLength: 50,
          ignoredKeys: [','],
          customMenuMessage: (searchInput) => (
            <Flex alignItems="center" css={{ gap: '$1' }}>
              <Text>{i18n.t('Create')}</Text>
              <Chip label={searchInput} />
            </Flex>
          ),
        }}
        customHeader={
          <Text variant="description" size="1" css={{ padding: '6px 12px' }}>
            {anyComputerHasTags
              ? i18n.t('Select a tag or create new')
              : i18n.t('Type to create a tag')}
          </Text>
        }
        componentCss={{
          label: { fontSize: '14px' },
          trigger: { width: '500px' },
          menu: { width: '500px' },
          valueContainer: { maxWidth: '500px' },
        }}
        testId="add-tags"
      />

      {anyComputerHasTags && (
        <MultiSelect
          label={i18n.t('Remove tags')}
          value={toRemoveTags}
          onChange={(t) => setToRemoveTags([...t])}
          options={commonTagsWithoutToAdd}
          placeholder={i18n.t('Select tags')}
          searchable
          searchFn={setSearchTerm}
          hideNoOptionsFoundMessage={!commonTagsWithoutToAdd.length}
          customHeader={
            !commonTagsWithoutToAdd.length && (
              <Flex
                alignItems="center"
                justifyContent="center"
                css={{ padding: '20px 0' }}
              >
                <Text variant="description">
                  {i18n.t('No tags available to remove')}
                </Text>
              </Flex>
            )
          }
          componentCss={{
            label: { fontSize: '14px' },
            trigger: { width: '500px' },
            menu: { width: '500px' },
            valueContainer: { maxWidth: '500px' },
            option: {
              "& > *, [role='label'], [role='label'] > span": { width: '100%' },
            },
          }}
        />
      )}
    </Flex>
  );

  const footer = (
    <Flex alignItems="center" justifyContent="end" gap="sm">
      <Button
        compact
        variant="subtle"
        onClick={onHide}
        disabled={isUpdatingComputersTags}
      >
        {i18n.t('Cancel')}
      </Button>
      <Button
        compact
        variant="primary"
        disabled={!hasAnyTagsSelected || isUpdatingComputersTags}
        onClick={() =>
          updateComputersTags({
            computer_ids: computerIds,
            add_tags: toAddTags,
            remove_tags: toRemoveTags,
          })
            .then(() => {
              toast({
                title: i18n.t('Tags will be updated. This may take some time.'),
                variant: 'success',
                style: toastStyle,
              });
              onHide();
            })
            .catch(() =>
              toast({
                title: i18n.t('Failed to update tags.'),
                variant: 'error',
                style: toastStyle,
              }),
            )
        }
      >
        {i18n.t('Save')}
      </Button>
    </Flex>
  );

  return (
    <Dialog
      isOpen
      closeOnEscape
      onOpenChange={onHide}
      title={i18n.t('Edit tags for {numDevices}', {
        numDevices: i18n.common.numDevices(computerIds.length),
      })}
      content={content}
      footer={footer}
      css={{ zIndex: 2000, width: '560px' }}
    />
  );
};

export default BulkEditComputersModal;
