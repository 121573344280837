import {
  DropdownMenuPrimitives as Dropdown,
  Flex,
  Icon,
  Text,
} from '@kandji-inc/nectar-ui';

type ClosedSidebarProfileDropdownTriggerProps = {
  isDropdownOpen: boolean;
  userFirstName?: string;
  userLastName?: string;
};

export const ClosedSidebarProfileDropdownTrigger = ({
  isDropdownOpen,
  userFirstName,
  userLastName,
}: ClosedSidebarProfileDropdownTriggerProps) => (
  <Dropdown.Trigger asChild data-testid="sidebar-user-name">
    <Flex
      alignItems="center"
      justifyContent="center"
      css={{
        border: '1px solid transparent',
        cursor: 'pointer',
        height: '52px',
        width: '52px',
        backgroundColor: isDropdownOpen ? '#EAEAEA' : 'transparent',
        borderRadius: 'var(--radii-1)',

        '&:hover': {
          backgroundColor: '#EAEAEA',
        },

        '&:active': {
          border: '1px solid var(--colors-primary_focus_ring)',
        },
      }}
    >
      <Flex
        alignItems="center"
        justifyContent="center"
        css={{
          width: '40px',
          height: '40px',
          borderRadius: '50%',
          background: 'linear-gradient(90deg, #7ea8d3, #718194)',
          padding: '1.5px',
          mask: 'radial-gradient(circle, white 80%, transparent 81%)',
          WebkitMask: 'radial-gradient(circle, white 80%, transparent 81%)',
        }}
      >
        <Flex
          alignItems="center"
          justifyContent="center"
          css={{
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            backgroundColor: '$neutral0',
          }}
        >
          {userFirstName || userLastName ? (
            <Text variant="secondary" size={3} css={{ fontWeight: '$medium' }}>
              {`${userFirstName?.[0]}${userLastName?.[0]}`}
            </Text>
          ) : (
            <Icon name="user" style={{ color: 'var(--colors-neutral70)' }} />
          )}
        </Flex>
      </Flex>
    </Flex>
  </Dropdown.Trigger>
);
