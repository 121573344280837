import {
  type CSS,
  type IconNames,
  ScrollContainer,
  Table,
  Tbody,
  Thead,
  Tr,
} from '@kandji-inc/nectar-ui';
import {
  type ColumnDef,
  type ExpandedState,
  type Row,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import React, { useCallback, useMemo } from 'react';
import {
  TextCell,
  getHeaderDisplayNameFromMeta,
} from 'src/features/visibility/prism/utils/column-utils';
import { HeaderCell, RowCell } from './components/cells';
import { SelectionColumn } from './components/selection-column';
import { useToggleResizingClass } from './hooks';
import { getTableCss } from './utils';

export interface SortState {
  col?: string;
  direction?: 'asc' | 'desc' | 'none';
}

interface MenuOption {
  label: string;
  icon?: IconNames;
  onClick?: () => void;
  disabled?: boolean;
}

export const DataTable = <TData,>({
  columns,
  pinnedColumns,
  data,
  rowId = 'id',
  getRowCanExpand,
  getExpandableContent,
  onRowClick,
  getColumnMenu,
  getColumnTooltip,
  columnSizing,
  searchTerm = '',
  selectionModel = {},
  sort,
  offsets,
  css: externalCss,
}: {
  columns: ColumnDef<TData, any>[];
  pinnedColumns: string[];
  data: TData[];
  rowId?: string;
  onRowClick?: (
    row: TData,
    e: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
  ) => void;
  columnSizing?: {
    sizes: Record<string, number>;
    setSizes?: (sizes: Record<string, number>) => void;
  };
  getColumnMenu?: (column: ColumnDef<TData>) => MenuOption[];
  getColumnTooltip?: (column: ColumnDef<TData>) => React.ReactNode;
  getRowCanExpand?: (row: Row<TData>) => boolean;
  getExpandableContent?: (row: Row<TData>) => React.ReactNode;
  searchTerm?: string;
  selectionModel?: {
    selection?: string[];
    setSelection?: (selection: string[]) => void;
    maxCount?: number;
    hideSelectionColumn?: boolean;
    customSelectionFunc?: (row: any) => boolean;
  };
  sort?: { sortState: SortState; setSortState?: (state: SortState) => void };
  offsets: { content?: number; table?: number; container?: number };
  css?: CSS;
}) => {
  const [expanded, setExpanded] = React.useState<ExpandedState>({});

  // istanbul ignore next
  const defaultColumn = {
    header: getHeaderDisplayNameFromMeta,
    cell: TextCell,
    footer: (info) => info.column.id,
    size: 150,
    minSize: 100,
    maxSize: 750,
  };

  const selectedCss = {
    backgroundColor: '$blue05',
    borderTop: '1px solid $blue30',
    borderBottom: '1px solid $blue30',
  };

  const {
    maxCount,
    selection,
    setSelection,
    hideSelectionColumn = false,
    customSelectionFunc = null,
  } = selectionModel;

  const selectionColumn = useMemo(() => {
    if (selection && !hideSelectionColumn) {
      return SelectionColumn({
        rowId,
        selection,
        onCheckedChange: !setSelection
          ? undefined
          : ({
              checked,
              row,
              rows,
              isHeader,
            }: {
              checked: boolean | 'indeterminate';
              row: Row<TData>;
              rows: Row<TData>[];
              isHeader: boolean;
            }) => {
              if (isHeader) {
                if (checked === true || checked === 'indeterminate') {
                  setSelection([]);
                } else {
                  const selected = new Set(selection);
                  rows.forEach((r) => {
                    if (maxCount == null || selected.size < maxCount) {
                      selected.add(r.original[rowId]);
                    }
                  });
                  setSelection(Array.from(selected));
                }
              } else if (!checked) {
                if (maxCount == null || selection.length < maxCount) {
                  setSelection([...selection, row.original[rowId]]);
                }
              } else {
                setSelection(
                  selection.filter((id) => id !== row.original[rowId]),
                );
              }
            },
      });
    }
  }, [hideSelectionColumn, maxCount, rowId, selection, setSelection]);

  const handleSort = useCallback(
    (columnId) => {
      if (!sort?.sortState || !sort?.setSortState) return;
      if (sort.sortState.col === columnId) {
        const { direction } = sort.sortState;
        if (direction === 'asc') {
          sort.setSortState({ col: columnId, direction: 'desc' });
        } else if (direction === 'desc') {
          sort.setSortState({ col: columnId, direction: 'none' });
        } else {
          sort.setSortState({ col: columnId, direction: 'asc' });
        }
      } else {
        sort.setSortState({ col: columnId, direction: 'asc' });
      }
    },
    [sort?.setSortState, sort?.sortState],
  );

  const tableColumnSizing = columnSizing || { sizes: {} };

  const table = useReactTable({
    columns: selectionColumn ? [selectionColumn, ...columns] : columns,
    data,
    defaultColumn,
    onExpandedChange: setExpanded,
    getExpandedRowModel: getExpandedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getRowCanExpand: getRowCanExpand,
    state: {
      expanded,
      columnSizing: tableColumnSizing.sizes,
      globalFilter: searchTerm,
    },
  });

  const isEmpty =
    columns.length === 0 || (table.getRowModel()?.rows?.length || 0) === 0;

  const {
    content: contentOffset,
    table: tableOffset,
    container: containerOffset,
  } = offsets;

  const css = useMemo(
    () => getTableCss({ contentOffset, tableOffset }),
    [contentOffset, tableOffset],
  );

  const tableCss = {
    ...css.tableContainer,
    ...(isEmpty ? { borderBottom: 'none' } : {}),
    maxHeight: `calc(100% - ${containerOffset || 0}px)`,
  };

  const { isResizingColumn, startSize, deltaOffset } =
    table.getState().columnSizingInfo;

  React.useEffect(() => {
    // Reset expanded rows data changes
    setExpanded({});
  }, [data]);

  React.useEffect(() => {
    if (
      typeof tableColumnSizing.setSizes === 'function' &&
      isResizingColumn &&
      startSize != null &&
      deltaOffset != null
    ) {
      const size = Math.min(
        Math.max(startSize + deltaOffset, defaultColumn.minSize),
        defaultColumn.maxSize,
      );
      tableColumnSizing.setSizes({
        ...tableColumnSizing.sizes,
        [isResizingColumn]: size,
      });
    }
  }, [
    isResizingColumn,
    startSize,
    deltaOffset,
    tableColumnSizing.setSizes,
    tableColumnSizing.sizes,
    defaultColumn.maxSize,
    defaultColumn.minSize,
  ]);

  useToggleResizingClass(isResizingColumn);

  return (
    <ScrollContainer
      css={{ ...tableCss, ...externalCss }}
      showScrollShadowRight
    >
      <Table aria-label="data" css={css.table}>
        <Thead data-pinned>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                const minSize =
                  header.column.columnDef.minSize || defaultColumn.minSize;
                const maxSize =
                  header.column.columnDef.maxSize || defaultColumn.maxSize;
                const size =
                  isResizingColumn === header.id
                    ? Math.min(
                        Math.max(startSize + deltaOffset, minSize),
                        maxSize,
                      )
                    : header.getSize();
                const menuOptions =
                  getColumnMenu && getColumnMenu(header.column.columnDef);
                const showMenu = menuOptions && menuOptions.length > 0;
                const headerPinned = pinnedColumns?.includes(header.column.id);
                const columnDefCSS = header.column.columnDef.meta?.css || {};
                const tooltip = getColumnTooltip
                  ? getColumnTooltip(header.column.columnDef)
                  : null;
                return (
                  <HeaderCell
                    title={
                      tooltip
                        ? null
                        : header.column.columnDef.meta?.displayName || header.id
                    }
                    columnId={header.id}
                    key={header.id}
                    colSpan={header.colSpan}
                    size={size}
                    showMenu={showMenu}
                    showMenuOnHover={showMenu}
                    menuOptions={menuOptions}
                    resizable={
                      typeof tableColumnSizing.setSizes === 'function' &&
                      header.column.getCanResize()
                    }
                    isResizingColumn={isResizingColumn}
                    handleResize={header.getResizeHandler()}
                    css={{
                      ...columnDefCSS,
                      pointerEvents:
                        isResizingColumn === header.id ? 'none' : 'auto',
                      left:
                        headerPinned && selectionColumn
                          ? `${selectionColumn.size}px!important`
                          : columnDefCSS.left,
                    }}
                    data-pinned={headerPinned}
                    sort={
                      sort && header.column.columnDef.enableSorting !== false
                        ? {
                            state:
                              sort.sortState.col === header.id
                                ? sort.sortState.direction
                                : 'none',
                            onSort: () => handleSort(header.id),
                          }
                        : undefined
                    }
                    showSortOnHover={sort != null}
                    tooltip={tooltip}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </HeaderCell>
                );
              })}
            </Tr>
          ))}
        </Thead>
        {!isEmpty && (
          <Tbody>
            {table.getRowModel().rows.map((row) => (
              <React.Fragment key={row.id}>
                <Tr
                  css={onRowClick ? { cursor: 'pointer' } : {}}
                  onClick={(e) => {
                    e.stopPropagation();
                    return onRowClick && onRowClick(row.original, e);
                  }}
                >
                  {row.getVisibleCells().map((cell) => {
                    const cellPinned = pinnedColumns?.includes(cell.column.id);
                    const cellScope = cellPinned ? 'row' : undefined;
                    const title = cell.column.columnDef.meta?.hideCellTitle
                      ? ''
                      : (cell.getValue() as string);
                    const columnDefCSS = cell.column.columnDef.meta?.css || {};
                    const isSelected = customSelectionFunc
                      ? customSelectionFunc(row.original)
                      : selection?.includes(row.original[rowId]);
                    return (
                      <RowCell
                        columnId={cell.column.id}
                        key={cell.id}
                        data-pinned={cellPinned}
                        scope={cellScope}
                        size={cell.column.getSize()}
                        isResizingColumn={isResizingColumn}
                        title={title}
                        css={{
                          ...(isSelected ? selectedCss : {}),
                          ...columnDefCSS,
                          boxShadow:
                            row.getCanExpand() && row.getIsExpanded()
                              ? 'none'
                              : 'var(--shadows-inset_border_bottom_1) var(--colors-neutral20)',
                          left:
                            cellPinned && selectionColumn
                              ? `${selectionColumn.size}px!important`
                              : columnDefCSS.left,
                        }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </RowCell>
                    );
                  })}
                </Tr>
                {/* If the row is expanded, render the expanded UI as a separate row with a single cell that spans the width of the table */}
                {row.getIsExpanded() && (
                  <Tr>
                    <RowCell colSpan={row.getAllCells().length}>
                      {getExpandableContent(row)}
                    </RowCell>
                  </Tr>
                )}
              </React.Fragment>
            ))}
          </Tbody>
        )}
      </Table>
    </ScrollContainer>
  );
};
